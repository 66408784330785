<template>
  <div class="examStatistics">
    <div class="sub-tile">
      考试情况
      <el-button type="text" style="color: #3e4551" @click="linkTo()">
        查看更多考试
        <img src="@/assets/dashborad/icon-right.png" alt="" />
        <img class="on" src="@/assets/dashborad/icon-right.png" alt="" />
      </el-button>
    </div>
    <template v-if="listLoading || description.length > 0">
      <div v-if="description" class="tips-msg">
        <div v-html="description"></div>
      </div>
      <div v-show="$parent.chartType" v-loading="listLoading">
        <template v-if="teacherRoleId == 5 && subjectArr.length > 0">
          <el-table
            :key="teacherRoleId"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="year"
              label="日期"
              width="106"
              align="center"
              class-name="column-custom"
              fixed
            >
              <template #header>
                <div class="header-tram">
                  <div class="title-one">学年</div>
                  <div class="title-two">学科</div>
                  <div class="header-line"></div>
                </div>
              </template>
              <template slot-scope="{ row }">
                {{ row.year == "-1" ? "总计" : row.year + "级" }}
              </template>
            </el-table-column>
            <el-table-column
              v-for="(item, index) in subjectArr"
              :key="index"
              :label="item.subjectName"
              align="center"
            >
              <el-table-column
                :prop="item.subjectName"
                min-width="80"
                align="center"
                label="场次"
              >
                <template slot-scope="{ row }">
                  {{ row[item.subjectName] || "-" }}
                </template>
              </el-table-column>
              <el-table-column
                :prop="item.subjectName + index"
                label="考试间隔"
                min-width="80"
                align="center"
              >
                <template slot-scope="{ row }">
                  {{ row[item.subjectName + index] || "-" }}
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="teacherRoleId == 4 || teacherRoleId == 2">
          <el-table
            :key="teacherRoleId"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="subjectName"
              label="学科"
              width="116"
              align="center"
            >
            </el-table-column>
            <template v-if="teacherRoleId == 3 || teacherRoleId == 4">
              <el-table-column prop="examNum" label="考试场次" align="center">
              </el-table-column>
              <el-table-column
                prop="sheetNum"
                label="答题卡模版"
                width="116"
                align="center"
              >
              </el-table-column>
            </template>
            <template v-if="teacherRoleId == 2">
              <el-table-column
                prop="examNum"
                label="进行考试场次"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="examStudentNum"
                label="参考学生数量"
                width="116"
                align="center"
              >
              </el-table-column>
            </template>
            <el-table-column
              prop="examInterval"
              label="考试间隔"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="examDate"
              label="最近考试时间"
              width="116"
              align="center"
            >
            </el-table-column>
            <el-table-column
              v-if="teacherRoleId == 3 || teacherRoleId == 4"
              prop="scanNum"
              label="答题卡扫描量"
              width="116"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="markNum"
              label="人均阅卷量"
              width="116"
              align="center"
            >
            </el-table-column>
          </el-table>
        </template>
        <template v-if="teacherRoleId == 3">
          <el-table
            :key="teacherRoleId"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="teacherName"
              label="教师"
              width="116"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="paperNum" label="阅卷场次" align="center">
            </el-table-column>
            <el-table-column
              prop="markTotalNum"
              label="实际阅卷总量"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="avgMarkTime"
              label="平均批阅时长"
              align="center"
            >
            </el-table-column>
          </el-table>
        </template>
        <div
          v-if="teacherRoleId != 1"
          class="more-box"
          :class="{ 'more-box-on': !showTab }"
          @click="checkMore()"
        >
          <div>
            <i class="el-icon-d-arrow-right"></i>
            <template v-if="!showTab"> 展开 </template>
            <template v-else> 收起 </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <noData>
        <div slot="message" style="text-align: center">
          近期暂无学科测验
          <!-- ，去
          <el-button type="text" @click="linkTo()"> 创建考试 </el-button> -->
        </div>
      </noData>
    </template>
  </div>
</template>
<script>
import {
  bkzzExamInfo,
  rkjsExamInfo,
  xldExamInfo,
  njzzExamInfo,
  bzrExamInfo,
} from "@/core/api/dashboard/index";
export default {
  name: "ExamStatistics",
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      description: "",
      tableData: [],
      subjectArr: [],
      showTab: false,
      listLoading: false,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      let data = JSON.parse(JSON.stringify(this.$parent.filterObj));
      // 根据不同角色去调用方法
      this.listLoading = true;
      if (this.teacherRoleId == 5) {
        this.getxldExamInfo(data);
      }
      if (this.teacherRoleId == 4) {
        this.getnjzzExamInfo(data);
      }
      if (this.teacherRoleId == 3) {
        this.getbkzzExamInfo(data);
      }
      if (this.teacherRoleId == 2) {
        this.getbzrExamInfo(data);
      }
      if (this.teacherRoleId == 1) {
        this.getrkjsExamInfo(data);
      }
      this.description = "";
    },
    checkMore() {
      this.showTab = !this.showTab;
      this.initList();
    },
    initList() {
      if (this.showTab) {
        this.tableData = this.oldList;
      } else {
        this.tableData = this.oldList.filter((item, index) => index < 5);
      }
    },
    // 校领导
    getxldExamInfo(data) {
      xldExamInfo(data)
        .then((res) => {
          this.description = res.data.data.description;
          this.oldList = res.data.data.list.map((item) => {
            item.basicList.map((index, i) => {
              item[index.subjectName] = index.examNum;
              item[index.subjectName + i] = index.examInterval;
            });
            return item;
          });
          this.oldList = JSON.parse(JSON.stringify(this.oldList));
          this.initList();
          // 过滤下科目列表;
          this.getSubjectItem();
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getSubjectItem() {
      if (!this.oldList || this.oldList.length == 0) return;
      let subjectArr = [];
      let subjectIdArr = [];
      this.oldList.map((item) => {
        item.basicList.map((index) => {
          if (subjectIdArr.indexOf(index.subjectId) == -1) {
            subjectArr.push(index);
            subjectIdArr.push(index.subjectId);
          }
        });
      });
      this.subjectArr = subjectArr;
      // console.log();
    },
    // 备课组长
    getbkzzExamInfo(data) {
      bkzzExamInfo(data)
        .then((res) => {
          this.description = res.data.data.description;
          this.oldList = res.data.data.list || [];
          this.initList();
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 年级组长
    getnjzzExamInfo(data) {
      njzzExamInfo(data)
        .then((res) => {
          this.description = res.data.data.description;
          this.oldList = res.data.data.list || [];
          this.initList();
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 班主任
    getbzrExamInfo(data) {
      bzrExamInfo(data)
        .then((res) => {
          this.description = res.data.data.description;
          this.oldList = res.data.data.list || [];
          this.initList();
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    // 任课教师
    getrkjsExamInfo(data) {
      rkjsExamInfo(data)
        .then((res) => {
          this.description = res.data.data;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    linkTo() {
      this.$router.push({
        path: "/exam/exam",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.examStatistics {
  padding-top: 0;
  background-color: #ffffff;
  margin-bottom: 18px;
  ::v-deep .column-custom {
    padding: 0px !important;
  }
  ::v-deep .el-table--border .el-table__cell:first-child .cell {
    padding: 0 !important;
  }
  .header-tram {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 74px;
    .title-one {
      left: 23px;
      top: 41px;
    }
    .title-two {
      left: 57px;
      top: 10px;
    }

    div {
      position: absolute;
    }
    .header-line {
      padding-left: 0;
      width: 1px;
      height: 129px;
      transform: rotate(-55deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      background-color: #ebeef5;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
}
</style>
