<template>
  <div class="focusOnstudents">
    <div class="running-condition">
      <div class="label">
        重点关注学生
        <el-tooltip
          class="item"
          effect="dark"
          content="最近一次考试需重点关注学生"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <span v-if="examObj.statId" class="label-span">
          最近一次考试：{{ examObj.name }}
        </span>
      </div>
    </div>
    <div v-if="examObj.statId" class="student-type">
      <div class="student-type-item">
        <div
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :class="{ on: 2 == indexStudentType }"
          @click="checkItem(2)"
        >
          拔尖生
        </div>
        <div
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :class="{ on: 3 == indexStudentType }"
          @click="checkItem(3)"
        >
          后进生
        </div>
        <div :class="{ on: 4 == indexStudentType }" @click="checkItem(4)">
          跟踪生
        </div>
      </div>
      <div style="cursor: pointer" @click="linkTo()">
        查看学生报告<img src="@/assets/dashborad/icon-right.png" alt="" />
      </div>
    </div>
    <div
      v-if="
        examObj.statId && (indexStudentType === 2 || indexStudentType === 3)
      "
      class="student-filter"
    >
      <div class="student-filter-left">
        {{ indexStudentType === 5 ? "校排名后：" : "校排名前："
        }}<el-input
          v-model="topOrBackward"
          oninput="value=value.replace(/[^\d]/g,'')"
          style="width: 117px; margin-right: 24px"
          :maxlength="5"
          @blur="topOrBackward = $event.target.value"
        ></el-input>
        <el-button type="primary" @click="initView()">搜索</el-button>
      </div>
    </div>
    <el-table
      v-if="listLoading || tableData.length > 0"
      v-loading="listLoading"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column prop="label" fixed label="姓名" align="center">
        <template slot-scope="{ row }">
          <span class="name" @click="toStudent(row)">{{ row.name }}</span>
        </template>
      </el-table-column>
      <div v-if="indexStudentType === 1">
        <el-table-column prop="label" label="薄弱学科" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.littleSubject && row.littleSubject.length">
              <span class="subjectShit">{{
                row.littleSubject.toString()
              }}</span>
              稍薄弱
            </span>
            <span
              v-if="
                row.littleSubject &&
                row.seriousSubject &&
                row.littleSubject.length &&
                row.seriousSubject.length
              "
              >,</span
            >
            <span v-if="row.seriousSubject && row.seriousSubject.length">
              <span class="subjectShit err">{{
                row.seriousSubject.toString()
              }}</span>
              严重偏科
            </span>
          </template>
        </el-table-column>
        <div v-for="(item, index) in subjectData" :key="'subjectData' + index">
          <el-table-column :label="item.subjectName" align="center">
            <template slot-scope="{ row }">
              {{ row.subjects[index].score }}
            </template>
          </el-table-column>
          <!-- 排名 -->
          <el-table-column :label="item.subjectName + '排名'" align="center">
            <template slot-scope="{ row }">
              {{ row.subjects[index].rank }}
            </template>
          </el-table-column>
        </div>
      </div>

      <div v-if="indexStudentType != 1">
        <el-table-column
          prop="label"
          :label="maxRoleType == 2 ? '薄弱学科' : '潜力学科'"
          :width="subjectData.length ? 120 : 'auto'"
          align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.littleSubject && row.littleSubject.length">
              <span class="subjectShit">{{
                row.littleSubject.toString()
              }}</span>
              稍薄弱
            </span>
            <span
              v-if="
                row.littleSubject &&
                row.seriousSubject &&
                row.littleSubject.length &&
                row.seriousSubject.length
              "
              >,</span
            >
            <span v-if="row.seriousSubject && row.seriousSubject.length">
              <span class="subjectShit err">{{
                row.seriousSubject.toString()
              }}</span>
              严重偏科
            </span>
          </template>
        </el-table-column>
        <div v-for="(item, index) in subjectData" :key="'subjectData' + index">
          <el-table-column :label="item.subjectName" width="80" align="center">
            <template slot-scope="{ row }">
              {{ row.subjects[index].score }}
            </template>
          </el-table-column>
          <el-table-column
            :label="item.subjectName + '排名'"
            width="100"
            align="center"
          >
            <template slot-scope="{ row }">
              {{ row.subjects[index].rank }}
            </template>
          </el-table-column>
        </div>
      </div>
    </el-table>
    <template v-else>
      <noData>
        <div slot="message" style="text-align: center">
          <div>暂无数据</div>
        </div>
      </noData>
    </template>
    <div
      v-if="oldList.length > 5"
      class="more-box"
      :class="{ 'more-box-on': !showTab }"
      @click="checkMore()"
    >
      <div>
        <i class="el-icon-d-arrow-right"></i>
        <template v-if="!showTab"> 展开 </template>
        <template v-else> 收起 </template>
      </div>
    </div>
  </div>
</template>
<script>
import { studentTypeOptions } from "@/core/util/studentJson";

import {
  getTopData,
  getToporbackward,
  getTrack,
} from "@/core/api/newExamAcademic/director";
import { getStore } from "@/core/util/store";
import { getSubjectList } from "@/core/util/util";
export default {
  name: "FocusOnstudents",
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      studentTypeOptions: studentTypeOptions,
      indexStudentType: 2,
      tableData: [],
      onlineId: "",
      lineType: "1",
      indicatorList: [],
      examName: "",
      ruleId: "",
      topOrBackward: 20,
      criticalInterval: 10,
      showTab: false,
      listLoading: false,
      oldList: [],
      subjectData: [],
      examObj: {},
      topData: {},
      subjectName: "",
      subjectOption: getSubjectList(),
      maxRoleType: "",
    };
  },
  created() {
    const { teacherRoleType } = JSON.parse(
      localStorage.getItem("jzjx-userInfo")
    ).content;
    if (teacherRoleType) {
      const arr = teacherRoleType.split(",");
      this.maxRoleType = Math.max(...arr);
    }
  },
  methods: {
    showCol(field) {
      if (this.examObj.examType == 1) {
        if (
          this.topData.isSchoolDisplayReportClassRank == 1 &&
          this.examObj.isSchoolDisplayReportStudentRank == 1
        ) {
          return true;
        }
        return false;
      } else {
        if (this.tableData.length) {
          const result = this.tableData.every((item) => item[field] === null);
          return !result;
        }
        return false;
      }
    },
    async initView() {
      if (this.indexStudentType == 2 || this.indexStudentType == 3) {
        this.getToporbackward();
      }

      if (this.indexStudentType == 4) {
        this.getTrack();
      }
    },
    async init(examObj) {
      this.filterObj = JSON.parse(JSON.stringify(this.$parent.filterObj));
      this.examObj = examObj;
      this.schoolId = getStore({ name: "school_id" });
      await this.getTopData();

      this.initView();
    },
    async getTrack() {
      this.listLoading = true;
      let res;
      let data = {
        id: this.examObj.statId,
        classNum: this.filterObj.classNum,
      };
      if (this.examObj.examType == 1) {
        data.viewSchoolId = this.schoolId;
      } else {
        res = await getTrack(data);
      }
      this.listLoading = false;
      this.oldList = res.data.data || [];
      this.initList();
    },
    // 获取当前报告的topinfo
    async getTopData() {
      this.loading = true;
      let res;
      if (this.examObj.examType == 1) {
        //
      } else {
        res = await getTopData({
          id: this.examObj.statId,
          classNum: this.filterObj.classNum,
        });
      }
      this.topData = res.data.data;
      this.criticalInterval = this.topData.criticalInterval;
      this.loading = false;
    },
    async getToporbackward() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message.error("排名应为正整数");
        return;
      }
      let data = {
        classNum: this.filterObj.classNum,
        id: this.examObj.statId,
        topOrBackward: this.topOrBackward,
        type: this.indexStudentType == 2 ? 1 : 2,
      };
      let res;
      this.listLoading = true;
      try {
        if (this.examObj.examType == 1) {
          data.viewSchoolId = this.schoolId;
          //   res = await Uniongetbzrreportimportstudent(data);
        } else {
          res = await getToporbackward(data);
        }
        this.listLoading = false;
      } catch {
        this.listLoading = false;
      }

      this.oldList = res.data.data || [];
      this.initList();
    },

    checkMore() {
      this.showTab = !this.showTab;
      this.initList();
    },
    toStudent(row) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentId: row.studentId,
          id: this.examObj.statId,
        },
      });
      window.open(href, "_blank");
    },

    initList() {
      if (this.oldList.length) {
        this.subjectData = this.oldList[0].subjects;
      }
      if (this.showTab) {
        this.tableData = this.oldList;
      } else {
        this.tableData = this.oldList.filter((item, index) => index < 5);
      }
    },
    linkTo() {
      // this.$router.push({
      //   path: "/academic/reportStat",
      //   query: { id: this.examObj.statId },
      // });
      this.$parent.linkReport();
    },
    checkItem(item) {
      if (this.listLoading) return;
      this.topOrBackward = 20;
      this.tableData = [];
      this.indexStudentType = item;
      this.initView();
    },
  },
};
</script>
<style lang="scss" scoped>
.focusOnstudents {
  .name {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 10px;
    color: #2474ed;
  }
  .student-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .student-filter-left {
      display: flex;
      align-items: center;
      .el-select,
      .el-input {
        width: 120px;
        margin-right: 8px;
      }
    }
  }
  .student-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .student-type-item {
      display: flex;
      align-items: center;
      div {
        padding: 6px 10px;
        line-height: 1;
        cursor: pointer;
        margin-right: 14px;
        &:hover {
          background: #eff5ff;
          border-radius: 4px;
          color: #2474ed;
        }
      }
      .on {
        background: #eff5ff;
        border-radius: 4px;
        color: #2474ed;
      }
    }
  }
  .running-condition {
    // padding-top: 0;
    padding: 0;
    margin-bottom: 18px;
    .label-span {
      color: #83878f;
      font-size: 12px;
      margin-left: 6px;
      font-weight: 400;
    }
  }
  .seriousSubject {
    color: #fc7070;
  }
  .err {
    color: red;
  }
}
</style>
