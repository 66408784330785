<template>
  <div class="focusOnstudents">
    <div class="running-condition">
      <div class="label">
        重点关注学生
        <el-tooltip
          class="item"
          effect="dark"
          content="最近一次考试需重点关注学生"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <span class="label-span"> 最近一次考试：{{ examObj.name }} </span>
      </div>
    </div>
    <div v-if="examObj.statId" class="student-type">
      <div class="student-type-item">
        <div
          :isDashBoardUnionShow="{ field: 'topInfoOnlineVOs', type: 'length' }"
          :class="{ on: 2 == indexStudentType }"
          @click="checkItem(2)"
        >
          临界生
        </div>
        <div
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :class="{ on: 4 == indexStudentType }"
          @click="checkItem(4)"
        >
          拔尖生
        </div>
        <div
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :class="{ on: 5 == indexStudentType }"
          @click="checkItem(5)"
        >
          后进生
        </div>
        <div :class="{ on: 1 == indexStudentType }" @click="checkItem(1)">
          跟踪生
        </div>
      </div>
      <div style="cursor: pointer" @click="linkTo()">
        查看学生报告<img src="@/assets/dashborad/icon-right.png" alt="" />
      </div>
    </div>
    <div v-if="indexStudentType != 1" class="student-filter">
      <div class="student-filter-left">
        <template v-if="indexStudentType == 2">
          选择指标：
          <el-select
            v-model="onlineId"
            placeholder="请选择"
            @change="chechTab()"
          >
            <el-option
              v-for="(item, index) in indicatorList"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          临界分数：<el-input
            v-model="criticalInterval"
            style="width: 174px"
            oninput="value=value.replace(/[^\d]/g,'')"
            :maxlength="5"
            @blur="criticalInterval = $event.target.value"
          ></el-input>
        </template>
        <template v-if="indexStudentType === 5 || indexStudentType === 4">
          {{ indexStudentType === 5 ? "校排名后：" : "校排名前："
          }}<el-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 117px; margin-right: 24px"
            :maxlength="5"
            @blur="topOrBackward = $event.target.value"
          ></el-input>
        </template>

        <el-button type="primary" @click="initView()">搜索</el-button>
      </div>
      <div v-if="indexStudentType == 2">
        <el-radio-group v-model="lineType" size="small" @change="chechTab()">
          <el-radio-button label="1">上线临界</el-radio-button>
          <el-radio-button label="2">未上线临界</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <el-table
      v-if="listLoading || tableData.length > 0"
      v-loading="listLoading"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column prop="label" fixed label="姓名" align="center">
        <template slot-scope="{ row }">
          <span class="name" @click="toStudent(row)">{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="indexStudentType == 2"
        prop="date"
        label="潜力学科"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="row.weak == 0">-</span>
          <span v-if="row.weak == 1">稍薄弱</span>
          <span v-if="row.weak == 2" class="seriousSubject">严重偏科 </span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="总分" prop="totalScore" align="center">
      </el-table-column>
      <el-table-column
        v-if="showCol('totalRank')"
        label="总分排名"
        prop="totalRank"
        align="center"
      >
      </el-table-column> -->
      <el-table-column :label="subjectName" prop="score" align="center">
      </el-table-column>
      <el-table-column
        v-if="showCol('rank')"
        :label="subjectName + '排名'"
        prop="rank"
        align="center"
      >
      </el-table-column>
    </el-table>
    <template v-else>
      <noData>
        <div slot="message" style="text-align: center">
          <div>暂无数据</div>
        </div>
      </noData>
    </template>
    <div
      v-if="oldList.length > 5"
      class="more-box"
      :class="{ 'more-box-on': !showTab }"
      @click="checkMore()"
    >
      <div>
        <i class="el-icon-d-arrow-right"></i>
        <template v-if="!showTab"> 展开 </template>
        <template v-else> 收起 </template>
      </div>
    </div>
  </div>
</template>
<script>
import { studentTypeOptions } from "@/core/util/studentJson";
import { getstatonlineoption } from "@/core/api/academic/headReport";
import {
  getrkjsreportimportstudentToporbackward,
  getrkjsreportimportstudentCritical,
  getrenkejiaoshireporttopinfo,
  getrkjsreportimportstudentTrack,
} from "@/core/api/academic/teacher";
import {
  Uniongetrenkejiaoshireporttopinfo,
  UniongetrkjsreportimportstudentToporbackward,
  UniongetrkjsreportimportstudentCritical,
  UniongetrkjsreportimportstudentTrack,
} from "@/core/api/academic/unionReport";
import { getStore } from "@/core/util/store";
import { getSubjectList } from "@/core/util/util";
export default {
  name: "FocusOnstudents",
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      studentTypeOptions: studentTypeOptions,
      indexStudentType: 2,
      tableData: [],
      onlineId: "",
      lineType: "1",
      indicatorList: [],
      examName: "",
      ruleId: "",
      topOrBackward: 20,
      criticalInterval: 10,
      showTab: false,
      listLoading: false,
      oldList: [],
      examObj: {},
      topData: {},
      subjectName: "",
      subjectOption: getSubjectList(),
    };
  },
  created() {},
  methods: {
    showCol(field) {
      if (this.examObj.examType == 1) {
        if (
          this.topData.isSchoolDisplayReportClassRank == 1 &&
          this.examObj.isSchoolDisplayReportStudentRank == 1
        ) {
          return true;
        }
        return false;
      } else {
        if (this.tableData.length) {
          const result = this.tableData.every((item) => item[field] === null);
          return !result;
        }
        return false;
      }
    },
    async initView() {
      if (this.indexStudentType == 4 || this.indexStudentType == 5) {
        this.getbzrreportimportstudent();
      }
      if (this.indexStudentType == 2) {
        this.getrkjsreportimportstudentCriticalData();
      }
      if (this.indexStudentType == 1) {
        this.getrkjsreportimportstudentTrackData();
      }
    },
    async init(examObj) {
      this.filterObj = JSON.parse(JSON.stringify(this.$parent.filterObj));
      this.examObj = examObj;

      this.schoolId = getStore({ name: "school_id" });
      await this.getrenkejiaoshireporttopinfoData();
      if (this.indexStudentType == 2) {
        await this.getstatonlineoption();
      } else {
        this.getstatonlineoption();
      }
      this.initView();
      this.subjectName = this.subjectOption.filter(
        (item) => item.value == this.filterObj.subjectId
      )[0].label;
    },
    async getrkjsreportimportstudentTrackData() {
      this.listLoading = true;
      let res;
      let data = {
        id: this.examObj.statId,
        classNum: this.filterObj.classNum,
        subjectId: this.filterObj.subjectId,
        merge: false,
      };
      if (this.examObj.examType == 1) {
        data.viewSchoolId = this.schoolId;
        res = await UniongetrkjsreportimportstudentTrack(data);
      } else {
        res = await getrkjsreportimportstudentTrack(data);
      }
      this.listLoading = false;
      this.oldList = res.data.data || [];
      this.initList();
    },
    // 获取当前报告的topinfo
    async getrenkejiaoshireporttopinfoData() {
      this.loading = true;
      let res;
      if (this.examObj.examType == 1) {
        res = await Uniongetrenkejiaoshireporttopinfo({
          viewSchoolId: this.schoolId,
          id: this.examObj.statId,
          classNum: this.filterObj.classNum,
          subjectId: this.filterObj.subjectId,
          merge: false,
        });
      } else {
        res = await getrenkejiaoshireporttopinfo({
          id: this.examObj.statId,
          classNum: this.filterObj.classNum,
          subjectId: this.filterObj.subjectId,
          merge: false,
        });
      }
      this.topData = res.data.data;
      this.criticalInterval = this.topData.criticalInterval;
      this.loading = false;
    },
    async getbzrreportimportstudent() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message.error("排名应为正整数");
        return;
      }
      let data = {
        classNum: this.filterObj.classNum,
        subjectId: this.filterObj.subjectId,
        isAllClass: 0,
        id: this.examObj.statId,
        topOrBackward: this.topOrBackward,
        type: this.indexStudentType == 4 ? 1 : 2,
      };
      let res;
      this.listLoading = true;
      try {
        if (this.examObj.examType == 1) {
          data.viewSchoolId = this.schoolId;
          res = await UniongetrkjsreportimportstudentToporbackward(data);
        } else {
          res = await getrkjsreportimportstudentToporbackward(data);
        }
        this.listLoading = false;
      } catch {
        this.listLoading = false;
      }

      this.oldList = res.data.data || [];
      this.initList();
    },
    // 临界生
    async getstatonlineoption() {
      await getstatonlineoption({ id: this.examObj.statId }).then((res) => {
        this.indicatorList = res.data.data;
        this.onlineId = this.indicatorList[0].id;
      });
    },
    checkMore() {
      this.showTab = !this.showTab;
      this.initList();
    },
    toStudent(row) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentId: row.studentId,
          id: this.examObj.statId,
        },
      });
      window.open(href, "_blank");
    },
    async getrkjsreportimportstudentCriticalData() {
      if (/(^[1-9]\d*$)/.test(this.criticalInterval)) {
        this.listLoading = true;
        try {
          let res;
          let reqData = {
            interval: this.criticalInterval,
            onlineId: this.onlineId,
            id: this.examObj.statId,
            classNum: this.filterObj.classNum,
            merge: false,
            isAllClass: 0,
            subjectId: this.filterObj.subjectId,
          };
          if (this.examObj.examType == 1) {
            reqData.viewSchoolId = this.schoolId;
            res = await UniongetrkjsreportimportstudentCritical(reqData);
          } else {
            res = await getrkjsreportimportstudentCritical(reqData);
          }
          this.listLoading = false;
          this.studentObj = res.data.data;
          const { upStudents, downStudents } = res.data.data;
          if (this.lineType == 1) {
            this.oldList = upStudents;
          } else {
            this.oldList = downStudents;
          }
          this.initList();
        } catch {
          this.listLoading = false;
        }
      } else {
        this.$message.info("临界分数应为正整数");
      }
    },
    chechTab() {
      this.getrkjsreportimportstudentCriticalData();
    },
    initList() {
      if (this.showTab) {
        this.tableData = this.oldList;
      } else {
        this.tableData = this.oldList.filter((item, index) => index < 5);
      }
    },
    linkTo() {
      // this.$router.push({
      //   path: "/academic/reportStat",
      //   query: { id: this.examObj.statId },
      // });
      this.$parent.linkReport();
    },
    checkItem(item) {
      if (this.listLoading) return;
      this.criticalInterval = this.topData.criticalInterval;
      this.topOrBackward = 20;
      this.indexStudentType = item;
      this.tableData = [];
      this.initView();
    },
  },
};
</script>
<style lang="scss" scoped>
.focusOnstudents {
  .name {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 10px;
    color: #2474ed;
  }
  .student-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .student-filter-left {
      display: flex;
      align-items: center;
      .el-select,
      .el-input {
        width: 120px;
        margin-right: 8px;
      }
    }
  }
  .student-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .student-type-item {
      display: flex;
      align-items: center;
      div {
        padding: 6px 10px;
        line-height: 1;
        cursor: pointer;
        margin-right: 14px;
        &:hover {
          background: #eff5ff;
          border-radius: 4px;
          color: #2474ed;
        }
      }
      .on {
        background: #eff5ff;
        border-radius: 4px;
        color: #2474ed;
      }
    }
  }
  .running-condition {
    // padding-top: 0;
    padding: 0;
    margin-bottom: 18px;
    .label-span {
      color: #83878f;
      font-size: 12px;
      margin-left: 6px;
      font-weight: 400;
    }
  }
  .seriousSubject {
    color: #fc7070;
  }
}
</style>
