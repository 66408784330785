// 学生类型
export const studentTypeOptions = [
  {
    label: "临界生",
    value: 2,
  },
  {
    label: "拔尖生",
    value: 4,
  },
  {
    label: "后进生",
    value: 5,
  },
  {
    label: "跟踪生",
    value: 1,
  },
];
