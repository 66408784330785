<template>
  <div class="examInfo">
    <el-dialog
      title="查看考试信息"
      :visible.sync="detailDialogVisible"
      width="500px"
      class="info-box"
    >
      <el-form label-position="right" label-width="90px">
        <el-form-item label="考试名称：">{{ indexExamInfo.name }}</el-form-item>
        <el-form-item label="考试日期：">
          {{ indexExamInfo.examDate }}
        </el-form-item>
        <el-form-item label="考试学年：">
          {{ indexExamInfo.year }}
        </el-form-item>
        <el-form-item label="考试学段：">
          {{ indexExamInfo.level | setName(levelOptions) }}
        </el-form-item>
        <el-form-item label="风险级别：">
          {{ indexExamInfo.securityLevel | setName(examSecurityLevelOptions) }}
        </el-form-item>
        <el-form-item label="考生人数：">
          {{ indexExamInfo.examStudentCount }}
        </el-form-item>
        <el-form-item label="考试班级：">
          {{ indexExamInfo.classNums || "-" }}
        </el-form-item>
        <el-form-item label="考试类型：">
          {{ findLabelByValue(examTypeOptions, indexExamInfo.type) }}
        </el-form-item>
        <el-form-item label="考试科目：">
          <span
            v-for="(item, index) in indexExamInfo.subjectVOS"
            :key="index"
            style="margin-right: 18px"
            >{{ item.subjectName }}({{ item.score || "-" }})</span
          >
        </el-form-item>
        <el-form-item label="成绩发布：">
          教师：{{
            indexExamInfo.statusPublishTeacher ? "已发布" : "未发布"
          }}
          学生：{{ indexExamInfo.statusPublishStudent ? "已发布" : "未发布" }}
        </el-form-item>
        <el-form-item
          v-if="indexExamInfo.publishStudentSubject"
          label="发布科目："
        >
          {{
            indexExamInfo.publishStudentSubject
              | getListSubject(indexExamInfo.level)
          }}
        </el-form-item>
        <el-form-item label="发布模块：">
          {{ indexExamInfo.publishStudentModule | getModelList(modelList) }}
        </el-form-item>
        <!-- <el-form-item label="管理单位：">{{ examObj.schoolName }}</el-form-item>
        <el-form-item label="参考学校：">
          <div class="tags">
            <el-tag v-for="(item, index) in schoolIdList" :key="index">{{
              item.schoolName
            }}</el-tag>
          </div>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>
<script>
import { getSubjectList } from "@/core/util/util";
import { findByValue } from "@/core/util/util";
import {
  examTypeOptions,
  examSecurityLevelOptions,
  levelOptions,
  // gradeHighOptions,
  // gradeMiddleOptions,
  // subjectOptions,
  // examReportAutoSendOptions,
} from "@/core/util/constdata";
import { examinfo } from "@/core/api/exam/exam";
export default {
  name: "ExamInfo",
  filters: {
    getListSubject(value, level) {
      if (!value) return;
      let subjectOptions = getSubjectList(level);
      let subject = value.split(",").map(Number);
      let subjectItem = subjectOptions
        .map((item) => {
          if (subject.indexOf(item.subjectId) != -1) {
            return item.subjectName;
          } else {
            return false;
          }
        })
        .filter((item) => item);
      if (subject.indexOf(0) != -1) {
        subjectItem = ["总分"].concat(subjectItem);
      }
      return subjectItem.join(",");
    },
    getModelList(value, list) {
      if (!value) return;
      // let subjectOptions = getSubjectList(level);
      let subject = value.split(",").map(Number);
      let subjectItem = list
        .map((item) => {
          if (subject.indexOf(item.value) != -1) {
            return item.label;
          } else {
            return false;
          }
        })
        .filter((item) => item);
      return subjectItem.join(",");
    },
  },
  data() {
    return {
      examTypeOptions: examTypeOptions,
      levelOptions: levelOptions(),
      modelList: [
        {
          label: "仅卷面分",
          value: 1,
        },
        {
          label: "卷面分及超越率",
          value: 2,
        },
        {
          label: "等级分",
          value: 3,
        },
        {
          label: "小题分",
          value: 4,
        },
        {
          label: "知识点分析",
          value: 5,
        },
        {
          label: "试卷夹",
          value: 6,
        },
        {
          label: "学科雷达图",
          value: 7,
        },
        {
          label: "答题卡",
          value: 8,
        },
      ],
      examSecurityLevelOptions: examSecurityLevelOptions,
      indexExamInfo: {},
      detailDialogVisible: false,
      examObj: {},
    };
  },
  created() {},
  methods: {
    findLabelByValue(options, value) {
      return findByValue(options, value);
    },
    init(examObj) {
      this.examObj = examObj;
      let data = {
        examId: examObj.examId,
      };
      examinfo(data).then((res) => {
        this.indexExamInfo = res.data.data;
        this.detailDialogVisible = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.examInfo {
  .info-box {
    ::v-deep .el-form-item__content {
      line-height: 21px;
      color: #101011;
    }

    ::v-deep .el-form-item__label {
      margin-bottom: 0;
      line-height: 21px;
      color: #606266;
    }

    ::v-deep .el-dialog__body .el-form-item {
      padding: 0;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
