<template>
  <div class="studentChart">
    <div ref="studentChart" style="height: 250px"></div>
  </div>
</template>
<script>
export default {
  name: "StudentChart",
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    isLastChar(str, char) {
      return str.slice(-1) === char;
    },
    init() {
      const echarts = require("echarts");
      const chartDom = this.$refs.studentChart;
      const myChart = echarts.init(chartDom);
      myChart.on("click", (params) => {
        // 可以设置点击的类型与响应的系列
        if (params.seriesType === "line") {
          if (this.teacherRoleId == 1 || this.teacherRoleId == 2) {
            this.$parent.showIndexExam(params.data.statId, params.data.name);
          }
        }
      });
      let option;

      let key = "";
      if (this.$parent.searchType == 1) {
        key = "avgScore";
      }
      if (this.$parent.searchType == 2) {
        key = "avgScoreRate";
      }
      if (this.$parent.searchType == 3) {
        key = "top27ScoreRate";
      }
      if (this.$parent.searchType == 4) {
        key = "after27ScoreRate";
      }
      let { list } = this.$parent.studentSituation;
      let name = "";
      let sourceArr = [];

      if (this.teacherRoleId == 3 || this.teacherRoleId == 4) {
        let array = list.filter((item) => item.classNum != "年级");
        let gardeArr = list.filter((item) => item.classNum == "年级");
        sourceArr = array.map((item) => {
          let data = {
            product: item.classNum,
            班级: item[key],
          };
          if (gardeArr && gardeArr.length > 0) {
            data["年级均值"] = gardeArr[0][key];
          }
          return data;
        });
        option = {
          legend: {
            data: [
              {
                name: "班级",
                // 强制设置图形为圆。
                icon: "rect",
              },
              {
                name: "年级均值",
              },
            ],
            itemWidth: 18,
            itemHeight: 10,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            textStyle: { color: "#ffffff", fontWeight: "normal" },
            backgroundColor: "rgba(37,38,45,0.8);",
            borderColor: "rgba(37,38,45,0.8);",
          },
          dataset: {
            dimensions: ["product", "班级", "年级均值"],
            source: sourceArr,
          },

          xAxis: {
            type: "category",

            axisTick: {
              show: false,
            },
          },
          yAxis: {},
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: (8 / sourceArr.length) * 100,
            },
            {
              type: "slider",
            },
          ],
          color: ["#4D8DEFD9", "#FE7C29"],
          series: [
            {
              type: "bar",
              barMaxWidth: "40px",
              barGap: "80%",
            },
            {
              type: "line",
              name: "年级均值",
            },
          ],
        };
      }
      if (this.teacherRoleId == 2 || this.teacherRoleId == 1) {
        list.map((item) => {
          if (item.classNum != "年级") {
            let str = item.classNum.toString();
            name = this.isLastChar(str, "班") ? name : str + "班";
            item.examList.map((index) => {
              let data = {};
              data[name] = index[key];
              data.value = index.classNum;
              data.product = index.examDate;
              data.name = index.name;
              data.statId = index.statId;
              sourceArr.push(data);
            });
          }
        });
        list.map((item) => {
          if (item.classNum == "年级") {
            item.examList.map((index) => {
              sourceArr = sourceArr.map((i) => {
                if (i.product == index.examDate) {
                  i["年级"] = index[key];
                }
                return i;
              });
            });
          }
        });
        option = {
          legend: {
            data: [
              {
                name: name,
                // 强制设置图形为圆。
                icon: "rect",
              },
              {
                name: "年级",
              },
            ],
            itemWidth: 18,
            itemHeight: 10,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            textStyle: { color: "#ffffff", fontWeight: "normal" },
            backgroundColor: "rgba(37,38,45,0.8);",
            borderColor: "rgba(37,38,45,0.8);",
          },
          dataset: {
            dimensions: ["product", name, "年级"],
            source: sourceArr,
          },
          grid: { containLabel: true },
          xAxis: {
            type: "category",
            axisLabel: {
              rotate: -15, // 将文字旋转 45 度
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {},
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: (8 / sourceArr.length) * 100,
            },
            {
              type: "slider",
            },
          ],
          color: ["#4D8DEFD9", "#F86B6F"],
          series: [
            { type: "line", name: name, smooth: true },
            {
              type: "line",
              name: "年级",
              smooth: true,
            },
          ],
        };
      }
      if (this.teacherRoleId == 1 || this.teacherRoleId == 2) {
        this.$parent.showIndexExam(
          sourceArr[sourceArr.length - 1].statId,
          sourceArr[sourceArr.length - 1].name
        );
      }

      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.studentChart {
  margin: 18px 0;
}
</style>
