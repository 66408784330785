<template>
  <div class="studentSituation">
    <div class="running-condition">
      <div class="label">
        学科考情
        <el-tooltip
          class="item"
          effect="dark"
          content="成绩分析数据来源于已发布考试统计"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </div>
      <div class="running-condition-btn">
        <span v-if="chartType" @click="checkshow()">
          隐藏图表
          <img src="@/assets/dashborad/icon-hidden.png" alt="" />
        </span>
        <span v-else @click="checkshow()">
          显示图表
          <img src="@/assets/dashborad/icon-show.png" alt="" />
        </span>
        <span @click="getNewView()">
          刷新
          <img src="@/assets/dashborad/icon-refresh.png" alt="" />
        </span>
      </div>
    </div>
    <div class="cnt-bg" style="margin-bottom: 18px">
      <div v-if="teacherRoleId != 1 && teacherRoleId != 3" class="sroll-box">
        <img
          class="left-img"
          src="@/assets/dashborad/icon-left.png"
          alt=""
          @click="checkSroll(0)"
        />
        <div ref="subjuectRef" class="check-item-box edit-scroll-x-style">
          <div
            v-for="(item, index) in subjectOption"
            :key="index"
            :class="{ on: item.value == subjuectId }"
            @click="chechSub(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <img
          class="right-img"
          src="@/assets/dashborad/icon-right.png"
          alt=""
          @click="checkSroll(1)"
        />
      </div>
      <template v-if="tableData.length > 0 || listLoading">
        <div v-if="studentSituation.description" class="tips-msg">
          <div v-html="studentSituation.description"></div>
        </div>
        <div v-show="chartType">
          <div class="check-box">
            <div>
              <el-select
                v-if="teacherRoleId == 1 || teacherRoleId == 2"
                v-model="searchType"
                placeholder=""
                @change="checkType()"
              >
                <el-option label="平均分" :value="1"> </el-option>
                <el-option label="得分率" :value="2"> </el-option>
                <!-- <el-option label="前27%群体得分率" :value="3"> </el-option>
                <el-option label="年级后27%群体人数" :value="4"> </el-option> -->
              </el-select>
            </div>
            <!-- <div style="cursor: pointer" @click="showStudent()">
              查看前后27%名单
              <img src="@/assets/dashborad/icon-right.png" alt="" />
            </div> -->
          </div>
          <studentChart
            ref="studentChart"
            v-loading="listLoading"
            :teacher-role-id="teacherRoleId"
          >
          </studentChart>
          <div
            v-if="teacherRoleId == 2 || teacherRoleId == 1"
            class="click-tips"
          >
            说明：鼠标点中对应的考试，下表中考试将会加载该考试数据。
          </div>
          <el-table
            v-loading="listLoading"
            :data="tableData"
            border
            style="width: 100%"
          >
            <el-table-column
              prop="classNum"
              label="班级"
              width="80"
              align="center"
              fixed
            >
            </el-table-column>
            <template v-if="teacherRoleId != 1 && teacherRoleId != 2">
              <el-table-column
                prop="teacherName"
                label="任课教师"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="studentNum"
                label="考生人次"
                align="center"
              >
              </el-table-column>
              <el-table-column prop="examNum" label="考试场次" align="center">
              </el-table-column>
            </template>
            <el-table-column
              prop="avgScoreRate"
              label="平均得分率（%）"
              min-width="88"
              align="center"
            >
            </el-table-column>
            <el-table-column
              v-if="teacherRoleId == 1 || teacherRoleId == 2"
              prop="rank"
              label="排名"
              width="88"
              align="center"
            >
              <template slot-scope="{ row }">
                {{ row.rank || "-" }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="年级前27%群体人数" align="center">
              <el-table-column prop="top27Num" label="人数" align="center">
              </el-table-column>
              <el-table-column
                v-if="teacherRoleId == 3 || teacherRoleId == 4"
                prop="top27Proportion"
                label="占比"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="top27ScoreRate"
                label="平均得分率"
                align="center"
                min-width="88"
              >
              </el-table-column>
            </el-table-column>
            <el-table-column
              prop="name"
              label="年级后27%群体人数"
              align="center"
            >
              <el-table-column prop="after27Num" label="人数" align="center">
              </el-table-column>
              <el-table-column
                v-if="teacherRoleId == 3 || teacherRoleId == 4"
                prop="after27Proportion"
                label="占比"
                align="center"
              >
              </el-table-column>

              <el-table-column
                prop="after27ScoreRate"
                label="平均得分率"
                align="center"
                min-width="88"
              >
              </el-table-column>
            </el-table-column> -->
            <template v-if="statId">
              <el-table-column prop="name" :label="statName" align="center">
                <el-table-column prop="avgScore" label="平均分" align="center">
                  <template slot-scope="{ row }">
                    {{ getKey("avgScore", row.examList) }}
                  </template>
                </el-table-column>
                <el-table-column prop="rankScore" label="排名" align="center">
                  <template slot-scope="{ row }">
                    {{ getKey("rankScore", row.examList) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="avgScoreRate"
                  label="得分率（%）"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    {{ getKey("avgScoreRate", row.examList) }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="rankScoreRate"
                  label="排名"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    {{ getKey("rankScoreRate", row.examList) }}
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  min-width="110"
                  prop="top27ScoreRate"
                  label="前27%得分率"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    {{ getKey("top27ScoreRate", row.examList) }}
                  </template>
                </el-table-column> -->
                <!-- <el-table-column
                  min-width="110"
                  prop="after27ScoreRate"
                  label="后27%得分率"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    {{ getKey("after27ScoreRate", row.examList) }}
                  </template>
                </el-table-column> -->
              </el-table-column>
            </template>
          </el-table>
          <div
            v-if="oldList.length > 5"
            class="more-box"
            :class="{ 'more-box-on': !showTab }"
            @click="checkMore()"
          >
            <div>
              <i class="el-icon-d-arrow-right"></i>
              <template v-if="!showTab"> 展开 </template>
              <template v-else> 收起 </template>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <noData>
          <div slot="message" style="text-align: center">
            近期暂无学科测验
            <!-- ，去
            <el-button type="text" @click="linkTo()"> 创建考试 </el-button> -->
          </div>
        </noData>
      </template>
      <template v-if="teacherRoleId == 2 || teacherRoleId == 1">
        <hr />
        <template v-if="teacherRoleId == 1">
          <studentRkjs
            v-if="examObj.ruleId == 0"
            ref="studentRkjs"
            :teacher-role-id="teacherRoleId"
          >
          </studentRkjs>
          <studentNewRkjs
            v-else
            ref="studentNewRkjs"
            :teacher-role-id="teacherRoleId"
          ></studentNewRkjs>
        </template>
        <template v-else>
          <studentBzr
            v-if="examObj.ruleId == 0"
            ref="studentBzr"
            :teacher-role-id="teacherRoleId"
          ></studentBzr>
          <studentNewBzr
            v-else
            ref="studentNewBzr"
            :teacher-role-id="teacherRoleId"
          ></studentNewBzr>
        </template>
      </template>
    </div>
    <studentList ref="studentList"></studentList>
  </div>
</template>
<script>
import studentChart from "./studentChart";
import { getSubjectList } from "@/core/util/util";
import studentRkjs from "./studentRkjs.vue";
import studentBzr from "./studentBzr.vue";
import studentList from "./studentList.vue";
import studentNewRkjs from "./studentNewRkjs.vue";
import studentNewBzr from "./studentNewBzr.vue";
import {
  importstudent,
  examSituation,
  njzzexamSituation,
} from "@/core/api/dashboard/index";
export default {
  name: "StudentSituation",
  components: {
    studentChart,
    studentRkjs,
    studentList,
    studentBzr,
    studentNewRkjs,
    studentNewBzr,
  },
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      chartType: true,
      listLoading: false,
      searchType: 1,
      subjuectId: "",
      showTab: false,
      oldList: [],
      tableData: [],
      subjectOption: [],
      examObj: {},
      filterObj: {},
      studentSituation: {},
      statId: "",
      statName: "",
    };
  },
  created() {},
  methods: {
    getKey(key, examList) {
      let str = "";
      examList.map((item) => {
        if (item.statId == this.statId) {
          str = item[key];
        }
      });
      return str || "-";
    },
    showIndexExam(id, name) {
      this.statId = id;
      this.statName = name;
    },
    async getReportStat() {
      let filterObj = JSON.parse(JSON.stringify(this.$parent.filterObj));
      if (this.teacherRoleId == 2) {
        delete filterObj.subjectId;
      }
      filterObj.roleType = this.teacherRoleId;
      await importstudent(filterObj).then((res) => {
        this.examObj = res.data.data;
      });
    },
    async init(type) {
      this.filterObj = JSON.parse(JSON.stringify(this.$parent.filterObj));
      this.subjectOption = getSubjectList(this.filterObj.level);
      let ids = this.subjectOption.map((item) => item.value);
      if (ids.indexOf(this.subjuectId) == -1) {
        this.subjuectId = this.subjectOption[0].value;
      }

      if (this.teacherRoleId == 2 || this.teacherRoleId == 1) {
        await this.getReportStat();
        this.getexamSituation(type);
        if (!this.examObj.statId) {
          this.examObj = {};
          return;
        }
        if (this.examObj.ruleId == 0 || this.examObj.scoreType == 0) {
          if (this.teacherRoleId == 1) {
            this.$refs.studentRkjs.init(this.examObj);
          }
          if (this.teacherRoleId == 2) {
            this.$refs.studentBzr.init(this.examObj);
          }
        } else {
          if (this.teacherRoleId == 1) {
            this.$refs.studentNewRkjs.init(this.examObj);
          }
          if (this.teacherRoleId == 2) {
            this.$refs.studentNewBzr.init(this.examObj);
          }
        }
      }
      if (this.teacherRoleId == 3 || this.teacherRoleId == 4) {
        this.searchType = 2;
        this.getnjzzexamSituation(type);
      }
    },
    getnjzzexamSituation(type) {
      let filterObj = JSON.parse(JSON.stringify(this.$parent.filterObj));

      filterObj.roleType = this.teacherRoleId;
      filterObj.flash = type || 0;
      if (this.teacherRoleId == 4) {
        filterObj.subjectId = this.subjuectId;
      }
      this.listLoading = true;
      njzzexamSituation(filterObj)
        .then((res) => {
          this.studentSituation = res.data.data;
          this.oldList = res.data.data.list || [];
          this.initList();
          this.$refs.studentChart.init();
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    initList() {
      if (this.showTab) {
        this.tableData = this.oldList;
      } else {
        this.tableData = this.oldList.filter((item, index) => index < 5);
      }
      // console.log(this.studentSituation);
      // this.showIndexExam(
      //   this.studentSituation[0].statId,
      //   this.studentSituation[0].name
      // );
    },
    chechSub(item) {
      if (this.listLoading) return;
      this.subjuectId = item.value;
      if (this.teacherRoleId == 2 || this.teacherRoleId == 1) {
        this.getexamSituation();
      }
      if (this.teacherRoleId == 3 || this.teacherRoleId == 4) {
        this.getnjzzexamSituation();
      }
    },
    getexamSituation(type) {
      let filterObj = JSON.parse(JSON.stringify(this.$parent.filterObj));

      filterObj.roleType = this.teacherRoleId;
      filterObj.flash = type || 0;
      if (this.teacherRoleId == 2) {
        filterObj.subjectId = this.subjuectId;
      }
      this.listLoading = true;
      examSituation(filterObj)
        .then((res) => {
          this.studentSituation = res.data.data;
          this.oldList = res.data.data.list || [];
          this.initList();
          this.$refs.studentChart.init();
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    checkMore() {
      this.showTab = !this.showTab;
      this.initList();
    },
    checkType() {
      this.$refs.studentChart.init();
    },
    checkSroll(type) {
      if (type) {
        this.$refs.subjuectRef.scrollLeft =
          this.$refs.subjuectRef.scrollLeft + 150;
      } else {
        this.$refs.subjuectRef.scrollLeft =
          this.$refs.subjuectRef.scrollLeft - 150;
      }
    },
    checkshow() {
      this.chartType = !this.chartType;
    },
    showStudent() {
      this.$refs.studentList.init();
    },
    getNewView() {
      this.init(1);
    },
    linkReport() {
      this.$store
        .dispatch("report/getRoleTypeArr", this.examObj.statId)
        .then((res) => {
          sessionStorage.setItem("accountRole", JSON.stringify(res));
          const { href } = this.$router.resolve({
            path: "/academic/reportStat/reportDetail",
            query: {
              id: this.examObj.statId,
              level: this.examObj.level,
              examId: this.examObj.examId,
              ruleId: this.examObj.ruleId,
              examType: this.examObj.examType,
              isSchoolDisplayReportStudentRank:
                this.examObj.isSchoolDisplayReportStudentRank,
              schoolId: "",
            },
          });
          window.open(href, "_blank");
        });
    },
    linkTo() {
      this.$router.push({
        path: "/exam/exam",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.studentSituation {
  .click-tips {
    background: #f7faff;
    border-radius: 4px;
    color: #3e4551;
    padding: 10px;
    margin-bottom: 18px;
  }
  .check-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
  }
}
</style>
