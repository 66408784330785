<!-- 校领导 5 年级组长 4 备课组长 3 班主任 2 任课教师 1     0 代指教务 -->
<template>
  <div class="teacher teacher-dashboard">
    <div :key="teacherRoleId" class="dashboard-left">
      <teacherHead ref="teacherHead"></teacherHead>
      <div class="teacher-cnt">
        <div class="filter-box">
          <div class="left-box">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ filterObj.level | setName(levelOptions) }}
                <img src="@/assets/dashborad/icon-bottom.png" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in levelOptionsCopy"
                  :key="index"
                  @click.native="checkLevel(item)"
                  >{{ item.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown
              v-if="
                teacherRoleId == 4 ||
                teacherRoleId == 3 ||
                teacherRoleId == 2 ||
                teacherRoleId == 1
              "
            >
              <span class="el-dropdown-link">
                {{ filterObj.year | setName(yearOptoin) }}
                <img src="@/assets/dashborad/icon-bottom.png" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in yearOptoin"
                  :key="index"
                  @click.native="checkYear(item)"
                  >{{ item.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-if="teacherRoleId == 3 || teacherRoleId == 1">
              <span class="el-dropdown-link">
                {{ filterObj.subjectId | setName(subjectOption) }}
                <img src="@/assets/dashborad/icon-bottom.png" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in subjectItem"
                  :key="index"
                  @click.native="checkSubject(item)"
                  >{{ item.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown v-if="teacherRoleId == 2 || teacherRoleId == 1">
              <span class="el-dropdown-link">
                {{ filterObj.classNum | setName(classNumOption) }}班
                <img src="@/assets/dashborad/icon-bottom.png" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in classNumOption"
                  :key="index"
                  @click.native="checkClass(item)"
                  >{{ item.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="right-box">
            查询时间：
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ filterObj.type | setName(optionsTime) }}
                <img src="@/assets/dashborad/icon-bottom.png" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in optionsTime"
                  :key="index"
                  @click.native="checkTime(item)"
                  >{{ item.label }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <template
          v-if="
            teacherRoleId == 4 ||
            teacherRoleId == 3 ||
            teacherRoleId == 2 ||
            teacherRoleId == 1
          "
        >
          <studentSituation
            ref="studentSituation"
            :teacher-role-id="teacherRoleId"
          ></studentSituation>
        </template>
        <div class="running-condition">
          <div class="label">平台运行情况</div>
          <div class="running-condition-btn">
            <template v-if="teacherRoleId != 1">
              <span v-if="chartType" @click="checkshowH()">
                隐藏图表
                <img src="@/assets/dashborad/icon-hidden.png" alt="" />
              </span>
              <span v-else @click="checkshowH()">
                显示图表
                <img src="@/assets/dashborad/icon-show.png" alt="" />
              </span>
            </template>
            <span @click="getNewView()">
              刷新
              <img src="@/assets/dashborad/icon-refresh.png" alt="" />
            </span>
          </div>
        </div>
        <div class="cnt-bg">
          <examCnt
            ref="examCnt"
            :teacher-role-id="teacherRoleId"
            :filter-obj="filterObj"
          ></examCnt>
          <template v-if="teacherRoleId != 1">
            <hr />
            <moduleContents
              ref="moduleContents"
              :teacher-role-id="teacherRoleId"
            ></moduleContents>
            <hr />
            <APPattention
              v-if="
                teacherRoleId == 5 || teacherRoleId == 4 || teacherRoleId == 2
              "
              ref="APPattention"
              :teacher-role-id="teacherRoleId"
            ></APPattention>
          </template>
        </div>
      </div>
    </div>
    <information ref="information"></information>
  </div>
</template>
<script>
import information from "../components/information.vue";
import teacherHead from "./components/teacherHead.vue";
import examCnt from "./components/examCnt.vue";
import studentSituation from "./components/studentSituation.vue";
//
import { levelOptions } from "@/core/util/constdata";
import moduleContents from "@/views/dashboard/components/moduleContents.vue";
import APPattention from "@/views/dashboard/components/APPattention.vue";
import { getSubjectList } from "@/core/util/util";
import { commonAuthority } from "@/core/api/dashboard/index";
export default {
  name: "Teacher",
  components: {
    information,
    teacherHead,
    examCnt,
    moduleContents,
    APPattention,
    studentSituation,
  },
  data() {
    return {
      levelOptions: levelOptions(),
      levelOptionsCopy: [],
      teacherLevel: [],
      subjectOption: getSubjectList(),
      subjectItem: [],
      yearOptoin: [],
      classNumOption: [],
      optionsTime: [
        {
          label: "近一个月",
          value: 1,
        },
        {
          label: "近三个月",
          value: 2,
        },
        {
          label: "近半年",
          value: 3,
        },
        {
          label: "近一年",
          value: 4,
        },
      ],

      filterObj: {
        level: "",
        year: "",
        subjectId: "",
        type: 1,
        classNum: "",
      },
      chartType: true,
      viewKey: 1,
      teacherRoleId: 5,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        //
        this.initRoleId();
        // 初始化頁面
      },
    },
  },
  async created() {
    this.initRoleId(1);
    await this.getcommonAuthority();
    this.initView();
  },
  mounted() {},
  methods: {
    init() {
      this.$refs.examCnt.init();
      if (
        this.teacherRoleId == 4 ||
        this.teacherRoleId == 3 ||
        this.teacherRoleId == 2 ||
        this.teacherRoleId == 1
      ) {
        this.$refs.studentSituation.init();
      }
      if (
        this.teacherRoleId == 5 ||
        this.teacherRoleId == 4 ||
        this.teacherRoleId == 2
      ) {
        this.$refs.APPattention.init();
      }
      if (this.$refs.moduleContents) this.$refs.moduleContents.init();
    },
    async getcommonAuthority() {
      await commonAuthority().then((res) => {
        let list = res.data.data;
        // 获取当前角色信息
        this.teacerRoleList = list.filter(
          (item) => this.teacherRoleId == item.type
        );
        let levelList = this.teacerRoleList.map((item) => item.level).join(",");
        this.levelOptionsCopy = this.levelOptions.filter(
          (item) => levelList.indexOf(item.value) != -1
        );

        // levelOptions
        this.filterObj.level = this.teacerRoleList[0].level;
        this.filterObj.year = this.teacerRoleList[0].year;
        this.filterObj.subjectId = this.teacerRoleList[0].subjectId;
      });
    },
    // 切换学段
    checkLevel(item) {
      this.filterObj.level = item.value;
      let arr = this.teacerRoleList.filter(
        (item) => item.level == this.filterObj.level
      );

      this.filterObj.year = arr[0].year;
      this.filterObj.subjectId = arr[0].subjectId;
      this.initView();
    },
    // 切换班级
    checkTime(item) {
      this.filterObj.type = item.value;
      this.init();
    },
    // 切换班级
    checkClass(item) {
      this.filterObj.classNum = item.value;
      this.init();
    },
    // 切换学段
    checkSubject(item) {
      this.filterObj.subjectId = item.value;
      // 过滤班级
      if (this.teacherRoleId == 1 || this.teacherRoleId == 2) {
        this.initClass();
      }
      this.init();
    },
    // 切换学段
    checkYear(item) {
      this.filterObj.year = item.value;
      let arr = this.teacerRoleList.filter(
        (item) =>
          item.level == this.filterObj.level && item.year == this.filterObj.year
      );
      this.filterObj.subjectId = arr[0].subjectId;
      // 过滤科目
      if (this.teacherRoleId == 1 || this.teacherRoleId == 3) {
        this.initSubject();
      }
      // 过滤班级
      if (this.teacherRoleId == 1 || this.teacherRoleId == 2) {
        this.initClass();
      }
      this.init();
    },
    getNewView() {
      this.$refs.examCnt.init();
      if (this.$refs.moduleContents) this.$refs.moduleContents.init();
      if (this.$refs.APPattention) this.$refs.APPattention.initView();
    },
    initView() {
      // 获取列表中的学年
      this.initYear();
      // 过滤科目
      if (this.teacherRoleId == 1 || this.teacherRoleId == 3) {
        this.initSubject();
      }
      // 过滤班级
      if (this.teacherRoleId == 1 || this.teacherRoleId == 2) {
        this.initClass();
      }
      this.init();
    },
    // 初始化科目
    initSubject() {
      let subjectList = this.teacerRoleList.map((item) => {
        if (
          item.level == this.filterObj.level &&
          this.filterObj.year == item.year
        ) {
          return item.subjectId;
        }
      });
      // 去重
      subjectList = [...new Set(subjectList)];
      // 过滤
      subjectList = subjectList.filter((item) => item);
      this.subjectItem = [];
      this.subjectOption.map((item) => {
        if (subjectList.indexOf(item.value) != -1) {
          this.subjectItem.push(item);
        }
      });
    },
    initYear() {
      let yearList = this.teacerRoleList.map((item) => {
        if (item.level == this.filterObj.level) {
          return item.year;
        }
      });
      // 去重
      yearList = [...new Set(yearList)];
      // 过滤
      yearList = yearList.filter((item) => item);
      this.yearOptoin = yearList.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
    },
    initClass() {
      let classList = this.teacerRoleList.map((item) => {
        if (
          item.level == this.filterObj.level &&
          this.filterObj.year == item.year &&
          this.filterObj.subjectId == item.subjectId
        ) {
          return item.classNums.split(",");
        }
      });
      classList = classList.flat();
      // 去重
      classList = [...new Set(classList)];
      // 过滤
      classList = classList.filter((item) => item);

      this.classNumOption = classList.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      this.filterObj.classNum = this.classNumOption[0].value;
    },
    async initRoleId(type) {
      // <!-- 校领导 5 年级组长 4 备课组长 3 班主任 2 任课教师 1     0 代指教务 -->
      if (this.$route.path == "/dashboard/teacher") {
        this.teacherRoleId = 5;
      }
      if (this.$route.path == "/dashboard/grade/teacher") {
        this.teacherRoleId = 4;
      }
      if (this.$route.path == "/dashboard/preparation/teacher") {
        this.teacherRoleId = 3;
      }
      if (this.$route.path == "/dashboard/classLeader/teacher") {
        this.teacherRoleId = 2;
      }
      if (this.$route.path == "/dashboard/courseTeacher/teacher") {
        this.teacherRoleId = 1;
      }
      Object.assign(this.filterObj, this.$options.data().filterObj);
      this.$nextTick(() => {
        this.$refs.information.init();
      });

      if (!type) {
        await this.getcommonAuthority();
        this.initView();
      }
    },
    checkshowH() {
      this.chartType = !this.chartType;
    },
  },
};
</script>
<style lang="scss">
.teacher-dashboard {
  hr {
    border: none;
    border-bottom: 1px dashed #e9e9e9;
    margin-bottom: 18px;
  }
  .cnt-bg {
    background-color: #ffffff;
    padding: 18px;
  }
  .running-condition {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 18px;
    padding-bottom: 0;
    .label {
      font-size: 18px;
      color: #0a1119;
      font-weight: bold;
      .el-icon-warning {
        color: #256dde;
        font-size: 16px;
      }
    }
    .running-condition-btn {
      span {
        color: #2474ed;
        user-select: none;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.teacher {
  width: 1220px;
  display: flex;
  justify-content: space-between;
  margin: 18px auto;
  position: relative;
  ::v-deep {
    .el-button--text {
      font-weight: 400;
    }
    .edit-scroll-style {
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .edit-scroll-x-style {
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .more-box {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;

      i {
        transition: 0.2s linear;
        transform: rotate(270deg);
      }
    }
    .more-box-on {
      i {
        transform: rotate(90deg);
      }
    }
    .nums-tips {
      color: #2474ed;
    }
    .sroll-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        flex-shrink: 0;
        width: 16px;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .left-img {
        margin-right: 12px;
      }
      .right-img {
        margin-left: 12px;
      }
    }
    .check-item-box {
      display: flex;
      scroll-behavior: smooth;
      margin-bottom: 18px;
      div {
        white-space: nowrap;
        line-height: 14px;
        padding: 6px 10px;
        margin-right: 8px;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 2px;
        &:hover {
          background: #eff5ff;
          color: #2474ed;
        }
      }
      .on {
        background: #eff5ff;
        color: #2474ed;
      }
    }
    .sub-tile {
      font-size: 16px;
      color: #0a1119;
      font-weight: 500;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tips-msg {
      background: #f7faff;
      border-radius: 4px;
      padding: 10px;
      color: #3e4551;
      margin-bottom: 18px;
      line-height: 1.5;
      text-align: justify;
    }
    .el-table {
      margin-bottom: 18px;
      .el-table__cell {
        .cell {
          padding: 0 5px;
        }
      }
    }
    .el-table__fixed {
      height: 100% !important;
    }
    .el-table--scrollable-x .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      ///*<!--修改 滑块 -->*/
      // border: 1px solid #DEE1E7;
      &::-webkit-scrollbar-thumb {
        background: #d5d7da;
        border-radius: 10px;
      }
    }
  }

  .teacher-cnt {
    border-radius: 4px;
    overflow: hidden;
  }

  .filter-box {
    margin: 18px 0;
    background-color: #ffffff;
    padding: 16px 18px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    .el-dropdown-link {
      cursor: pointer;
      color: #0a1119;
    }
    .el-dropdown + .el-dropdown {
      margin-left: 12px;
    }
  }
  .dashboard-left {
    width: 861px;
    margin-bottom: 24px;
  }
}
</style>
