import request from "@/core/services/axios";
export const getTopData = (params) => {
  // 获取新高考班主任报告顶部数据
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbanzhurenreporttopinfo`,
    method: "GET",
    params: params,
  });
};
export const getBrach = (params) => {
  // 新高考-班主任报告-成绩分布
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbanzhurenreportscoredistribution`,
    method: "post",
    data: params,
  });
};
export const getCompetion = (params) => {
  // 新高考-班主任报告-班级竞争力
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportclasscompetitive`,
    method: "get",
    params: params,
  });
};
export const waveStudent = (params) => {
  // 新高考-班主任报告-重点关注学生-波动生
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportimportstudent/wave`,
    method: "get",
    params: params,
  });
};
export const getToporbackward = (params) => {
  // 新高考-班主任报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportimportstudent/toporbackward`,
    method: "get",
    params: params,
  });
};
export const getTrack = (params) => {
  // 新高考-班主任报告-重点关注学生-跟踪生
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportimportstudent/track`,
    method: "get",
    params: params,
  });
};
export const getQuetions = (params) => {
  // 新高考-班主任报告-应答情况分析-题目
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportpaperanaly/question`,
    method: "get",
    params: params,
  });
};
export const getKnowledge = (params) => {
  // 新高考-班主任报告-应答情况分析-知识点
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportpaperanaly/knowledge`,
    method: "get",
    params: params,
  });
};
export const getbzrreportpaperanaly = (params) => {
  // 新高考-班主任报告-应答情况分析-学生应答反馈
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportpaperanaly/student`,
    method: "get",
    params: params,
  });
};
export const getbzrreportdevelopanalysis = (params) => {
  // 新高考-班主任报告-发展性分析
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbzrreportdevelopanalysis`,
    method: "get",
    params: params,
  });
};
export const getbanzhurenreportscoredistribution = (params) => {
  // 新高考-班主任报告-成绩分布-分数分布
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbanzhurenreportscoredistribution`,
    method: "post",
    data: params,
  });
};
export const getbanzhurenreportscoredistributionrank = (params) => {
  // 新高考-班主任报告-应答情况分析-排名分布
  return request({
    url: `/report/statnewgaokaobanzhurenreport/getbanzhurenreportscoredistributionrank`,
    method: "post",
    data: params,
  });
};
