<template>
  <div class="teacherHead">
    <div class="header-time">
      <img src="@/assets/dashborad/icon-welcome.png" alt="" />
      <span>欢迎使用，{{ $parent.teacherRoleId | setName(roleList) }}！</span>
      上次使用时间：{{ getTime() }}
    </div>
    <div class="item-cnt">
      <div class="check-box">
        <div
          v-for="(item, index) in checkItem"
          v-show="!item.hidden"
          :key="index"
          class="check-item"
          :class="{ on: item.value == indexType }"
          @click="changeType(item)"
        >
          <img
            v-if="item.value != indexType"
            src="@/assets/dashborad/icon-right.png"
            alt=""
          />
          <img v-else src="@/assets/dashborad/icon-right-on.png" alt="" />
          {{ item.label }}
          <template v-if="item.number">({{ item.number }})</template>
        </div>
      </div>
      <div class="lenk-btn" @click="linkTo(1)">
        查看更多<img src="@/assets/dashborad/icon-right.png" alt="" />
      </div>
    </div>

    <div
      v-if="dataItem.length > 0 || listLoading"
      class="exam-item-box"
      :loading="listLoading"
      :style="indexType == 3 ? 'border:none' : ''"
    >
      <template v-if="indexType == 3">
        <div
          v-for="(item, index) in dataItem"
          :key="index"
          class="homework-item"
        >
          <div class="header-box">
            <div class="header-left">
              <div class="tips-item">
                {{ item.subjectName }}
              </div>
              <div class="tips-item">
                {{ getYear(item) }}
              </div>
              <div class="hw-title">
                {{ item.homeworkName }}
              </div>
              <div class="time">
                时间:{{ item.beginTime }}~{{ item.endTime }}
              </div>
            </div>
            <el-button type="text" @click="linkHwReport(item)">
              作业报告
            </el-button>
          </div>
          <div class="class-item-box edit-scroll-style">
            <div
              v-for="(bItem, bIndex) in item.classes"
              :key="bIndex"
              class="class-item"
            >
              <div class="class-left">
                <div class="class-name">
                  {{ bItem.classNum }}
                </div>
                <div class="nums">
                  已录入：{{ bItem.finishedCount }}/{{ bItem.studentCount }}人
                </div>
                <div class="nums">
                  {{ item.scoreMode == 0 ? "正确率" : "得分率" }}：{{
                    bItem.scoreRate
                  }}%
                </div>
              </div>
              <el-button type="text" @click="linkHwJp(item, bItem)">
                作业讲评
              </el-button>
            </div>
          </div>

          <!--  -->
        </div>
      </template>
      <template v-else>
        <div
          v-for="(item, index) in dataItem"
          :key="index"
          class="exam-item"
          :class="{
            'exam-statistics': indexType == 2,
            'exam-item-w': index % 2 == 1,
          }"
        >
          <div class="left-exam">
            <el-tooltip
              class="item"
              effect="dark"
              :content="indexType == 1 ? item.examName : item.name"
              placement="top"
              :open-delay="500"
            >
              <div class="name" @click="showModel(item)">
                {{ indexType == 1 ? item.examName : item.name }}
              </div>
            </el-tooltip>

            <template v-if="indexType == 1">
              <span>{{ item.level | setName(levelOptions) }}</span>
              <span>{{ item.year }}级</span>
              <span>{{ item.examDate }}</span>
            </template>
          </div>
          <div class="right-exam">
            <template v-if="indexType == 1">
              <el-button
                type="text"
                :disabled="item.hasPermission == 0"
                @click="linkToExam(item)"
              >
                进入考试
              </el-button>
            </template>
            <template v-if="indexType == 2">
              <el-button type="text" @click="toReport(item)"
                >查看报表</el-button
              >
              <el-button type="text" @click="goDetail(item)"
                >查看报告</el-button
              >
            </template>
          </div>
        </div>
      </template>
    </div>
    <template v-if="dataItem.length == 0 && !listLoading">
      <noData>
        <div slot="message" style="text-align: center">
          <div v-if="indexType == 1">近期暂无学科测验</div>
          <div v-if="indexType == 2">暂无最新报告</div>
          <div v-if="indexType == 3">暂无作业报告</div>
        </div>
      </noData>
    </template>
    <examInfo ref="examInfo"></examInfo>
  </div>
</template>
<script>
const checkItem = [
  {
    label: "作业报告",
    value: 3,
    hidden: false,
  },
  {
    label: "进行中的考试",
    value: 1,
    number: 0,
  },
  {
    label: "最新报告",
    value: 2,
  },
];
let roleList = [
  {
    label: "校领导工作台",
    value: 5,
  },
  {
    label: "年级组长工作台",
    value: 4,
  },
  {
    label: "备课组长工作台",
    value: 3,
  },
  {
    label: "班主任工作台",
    value: 2,
  },
  {
    label: "任课教师工作台",
    value: 1,
  },
];
import moment from "moment";
import examInfo from "./examInfo.vue";
import {
  examingList,
  reportList,
  homeworklist,
} from "@/core/api/dashboard/index";
import { levelOptions } from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
export default {
  name: "TeacherHead",
  components: { examInfo },
  data() {
    return {
      checkItem: checkItem,
      levelOptions: levelOptions(),
      indexType: 3,
      dataItem: [],
      roleList: roleList,
      listLoading: true,
    };
  },
  created() {
    this.initView();
  },
  methods: {
    toReport(item) {
      this.$store.dispatch("report/getRoleTypeArr", item.statId).then((res) => {
        this.$router.push({
          path: "/academic/once",
          query: {
            statId: item.statId,
            examDate: item.examDate,
            examType: item.examType,
            ruleId: item.ruleId,
            type: item.type,
            isUnionSubjectUser: item.isUnionSubjectUser,
            level: item.level,
            year: item.year,
            examId: item.examId,
          },
        });
        sessionStorage.setItem("accountRole", JSON.stringify(res));
      });
    },
    goDetail(item) {
      this.$store.dispatch("report/getRoleTypeArr", item.statId).then((res) => {
        this.$router.push({
          name: "/academic/reportStat/reportDetail",
          query: {
            id: item.statId,
            level: item.level,
            examId: item.examId,
            ruleId: item.ruleId,
            schoolId: "",
            schoolName: "",
            isSchoolDisplayReportStudentRank:
              item.isSchoolDisplayReportStudentRank,
            isUnionSubjectUser: item.isUnionSubjectUser,
            examType: item.examType,
          },
        });
        sessionStorage.setItem("accountRole", JSON.stringify(res));
      });
    },
    linkHwReport(item) {
      this.$router.push({
        path: "/homework/report",
        query: { hwId: item.hwId, scoreMode: item.scoreMode },
      });
    },
    linkHwJp(item, bItem) {
      this.$router.push({
        path: "/homework/commentary",
        query: {
          year: item.year,
          hwId: item.hwId,
          level: item.level,
          subjectName: item.subjectName,
          homeworkName: item.homeworkName,
          subjectId: item.subjectId,
          scoreMode: item.scoreMode,
          classNum: bItem.classNum,
        },
      });
    },
    getExamList() {
      let data = {
        current: 1,
        size: 5,
      };
      this.listLoading = true;
      examingList(data)
        .then((res) => {
          this.dataItem = res.data.data.records;
          this.checkItem[1].number = res.data.data.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getReportList() {
      let data = {
        current: 1,
        size: 5,
      };
      this.listLoading = true;
      reportList(data)
        .then((res) => {
          this.dataItem = res.data.data || [];
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    initView() {
      let routerList = getStore({ name: "router-list" });
      let array = JSON.parse(routerList);
      let arr = array.map((item) => item.url);
      // console.log(arr);
      let str = "/homework/list";
      if (arr.indexOf(str) != -1) {
        this.indexType = 3;
        this.checkItem[0].hidden = false;
      } else {
        this.indexType = 1;
        this.checkItem[0].hidden = true;
      }
      this.gethomeworklist();
    },
    linkToExam(item) {
      this.$router.push({
        path: "/exam/exam",
        query: { examId: item.examId },
      });
    },

    showModel(item) {
      if (this.indexType == 2 || this.indexType == 3) return;
      this.$refs.examInfo.init(item);
    },
    getYear(item) {
      if (!item) return;
      let itemKey = ["小", "初", "高", "大"];
      return itemKey[item.level - 1] + item.year;
    },
    linkTo(type) {
      let url = "";
      if (type && this.indexType == 2) {
        url = "/academic/reportStat";
      } else if (this.indexType == 3) {
        url = "/homework/list";
      } else {
        url = "/exam/exam";
      }
      this.$router.push({
        path: url,
      });
    },
    changeType(item) {
      if (this.indexType == item.value) return;
      this.dataItem = [];
      this.indexType = item.value;
      if (this.indexType == 1) {
        this.getExamList();
      }
      if (this.indexType == 2) {
        this.getReportList();
      }
      if (this.indexType == 3) {
        this.gethomeworklist();
      }
    },
    gethomeworklist() {
      this.listLoading = true;
      homeworklist()
        .then((res) => {
          this.dataItem = res.data.data || [];
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    getTime() {
      let time = "";
      let { lastLoginTime } = getStore({ name: "userInfo" });
      if (!lastLoginTime) {
        return "-";
      }
      time = moment(new Date(lastLoginTime).getTime()).format(
        "YYYY年MM月DD日  HH:mm:ss "
      );
      return time;
    },
  },
};
</script>
<style lang="scss" scoped>
.teacherHead {
  background-color: #ffffff;
  padding: 18px;
  border-radius: 4px;
  .homework-item {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #e9e9e9;
    margin-bottom: 12px;
    padding: 18px;
    .class-item {
      background: #fbfbfb;
      padding: 12px 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;
      .class-left {
        display: flex;
        align-items: center;
        > div {
          margin-right: 24px;
        }
      }
      .class-name {
        color: #0a1119;
      }
      .nums {
        color: #3e4551;
      }
    }
    .class-item-box {
      max-height: 257px;
      &::-webkit-scrollbar {
        width: 5px;
        height: 3px;
      }
    }

    .header-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-left {
        display: flex;
        align-items: center;
        .time {
          color: #83878f;
          font-size: 12px;
        }
        > div {
          margin-right: 8px;
        }
        .hw-title {
          max-width: 264px;
          overflow: hidden; /* 确保溢出的内容被隐藏 */
          white-space: nowrap; /* 确保文本在一行内显示 */
          text-overflow: ellipsis; /* 使用省略号表示文字被截断 */
        }
      }
      .tips-item {
        padding: 8px;
        font-size: 12px;
        background: #f7faff;
        border-radius: 4px;
        line-height: 13px;
        color: #2474ed;
      }
    }
  }
  .exam-item-box {
    border-radius: 4px;
    border: 1px dashed #e9e9e9;
    min-height: 58px;

    .exam-statistics.exam-item {
      .left-exam {
        .name {
          max-width: 550px;
        }
      }
    }
    .exam-item-w {
      background: #fbfbfb;
    }

    .exam-item {
      display: flex;
      border-bottom: 1px dashed #e9e9e9;
      padding: 18px;
      justify-content: space-between;

      .left-exam {
        display: flex;
        align-items: center;

        .name {
          color: #0a1119;
          font-weight: 400;
          max-width: 437px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
        }

        span {
          margin-left: 12px;
          color: #83878f;
          font-size: 12px;
        }
      }
      &:hover {
        background: #f7faff;
        .left-exam {
          .name {
            color: #2474ed;
          }
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .item-cnt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 18px 0;
    .lenk-btn {
      cursor: pointer;
    }
  }

  .check-box {
    display: flex;
    align-items: center;

    .check-item {
      display: flex;
      align-items: center;
      margin-right: 40px;
      font-size: 16px;
      color: #0a1119;
      cursor: pointer;
      font-weight: bold;
      justify-content: flex-start;

      img {
        // margin-right: 4px;
        width: 14px;
      }
    }

    .on {
      color: #2474ed;
    }
  }

  .header-time {
    padding: 12px;
    background: #f7faff;
    border-radius: 4px;
    display: flex;
    align-items: center;

    span {
      font-weight: bold;
    }
  }
}
</style>
