<template>
  <div class="focusOnstudents">
    <div class="running-condition">
      <div class="label">
        重点关注学生
        <el-tooltip
          class="item"
          effect="dark"
          content="最近一次考试需重点关注学生"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <span v-if="examObj.statId" class="label-span">
          最近一次考试：{{ examObj.name }}
        </span>
      </div>
    </div>
    <div v-if="examObj.statId" class="student-type">
      <div class="student-type-item">
        <div
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :class="{ on: 2 == indexStudentType }"
          @click="checkItem(2)"
        >
          拔尖生
        </div>
        <div
          v-isUnionShow="'isSchoolDisplayReportClassRank'"
          :class="{ on: 3 == indexStudentType }"
          @click="checkItem(3)"
        >
          后进生
        </div>
        <div :class="{ on: 4 == indexStudentType }" @click="checkItem(4)">
          跟踪生
        </div>
      </div>
      <div style="cursor: pointer" @click="linkTo()">
        查看学生报告<img src="@/assets/dashborad/icon-right.png" alt="" />
      </div>
    </div>
    <div
      v-if="
        (indexStudentType === 2 || indexStudentType === 3) && examObj.statId
      "
      class="student-filter"
    >
      <div class="student-filter-left">
        <template v-if="indexStudentType === 2 || indexStudentType === 3">
          {{ indexStudentType === 2 ? "校排名后：" : "校排名前："
          }}<el-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 117px; margin-right: 24px"
            :maxlength="5"
            @blur="topOrBackward = $event.target.value"
          ></el-input>
        </template>

        <el-button type="primary" @click="initView()">搜索</el-button>
      </div>
      <div></div>
    </div>
    <el-table
      v-if="listLoading || tableData.length > 0"
      v-loading="listLoading"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column prop="label" fixed label="姓名" align="center">
        <template slot-scope="{ row }">
          <span class="name" @click="toStudent(row)">{{ row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="indexStudentType == 4"
        prop="date"
        label="潜力学科"
        align="center"
      >
        <template slot-scope="{ row }">
          <span v-if="!row.weak">-</span>
          <div v-if="row.weak">
            <span style="color: #f56c6c">{{ row.weak }}</span>
            稍薄弱学科
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="总分" prop="totalScore" align="center">
      </el-table-column>
      <el-table-column label="总分排名" prop="totalRank" align="center">
      </el-table-column> -->
      <el-table-column :label="subjectName" prop="score" align="center">
      </el-table-column>
      <el-table-column :label="subjectName + '排名'" prop="rank" align="center">
      </el-table-column>
    </el-table>
    <template v-else>
      <noData>
        <div slot="message" style="text-align: center">
          <div>暂无数据</div>
        </div>
      </noData>
    </template>
    <div
      v-if="oldList.length > 5"
      class="more-box"
      :class="{ 'more-box-on': !showTab }"
      @click="checkMore()"
    >
      <div>
        <i class="el-icon-d-arrow-right"></i>
        <template v-if="!showTab"> 展开 </template>
        <template v-else> 收起 </template>
      </div>
    </div>
  </div>
</template>
<script>
import { studentTypeOptions } from "@/core/util/studentJson";

// import {} from "@/core/api/newExamAcademic/teaching";
import {
  getTopData,
  topOrBackward,
  trackStudent,
} from "@/core/api/newExamAcademic/teacher";
import { getStore } from "@/core/util/store";
import { getSubjectList } from "@/core/util/util";
export default {
  name: "FocusOnstudents",
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      studentTypeOptions: studentTypeOptions,
      indexStudentType: 2,
      tableData: [],
      onlineId: "",
      lineType: "1",
      indicatorList: [],
      examName: "",
      ruleId: "",
      topOrBackward: 20,
      criticalInterval: 10,
      showTab: false,
      listLoading: false,
      oldList: [],
      examObj: {},
      topData: {},
      subjectName: "",
      subjectOption: getSubjectList(),
    };
  },
  created() {},
  methods: {
    async initView() {
      if (this.indexStudentType == 2 || this.indexStudentType == 3) {
        this.gettopOrBackward();
      }
      if (this.indexStudentType == 4) {
        this.trackStudent();
      }
    },
    async init(examObj) {
      this.filterObj = JSON.parse(JSON.stringify(this.$parent.filterObj));
      this.examObj = examObj;

      this.schoolId = getStore({ name: "school_id" });
      await this.getrenkejiaoshireporttopinfoData();
      this.initView();
      this.subjectName = this.subjectOption.filter(
        (item) => item.value == this.filterObj.subjectId
      )[0].label;
    },

    // 获取当前报告的topinfo
    async getrenkejiaoshireporttopinfoData() {
      this.loading = true;
      let res;
      if (this.examObj.examType == 1) {
        //
      } else {
        res = await getTopData({
          id: this.examObj.statId,
          classNum: this.filterObj.classNum,
          subjectId: this.filterObj.subjectId,
          merge: false,
        });
      }
      this.topData = res.data.data;
      this.criticalInterval = this.topData.criticalInterval;
      this.loading = false;
    },
    async gettopOrBackward() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message.error("排名应为正整数");
        return;
      }
      let data = {
        classNum: this.filterObj.classNum,
        subjectId: this.filterObj.subjectId,
        isAllClass: 0,
        id: this.examObj.statId,
        topOrBackward: this.topOrBackward,
        type: this.indexStudentType == 2 ? 1 : 2,
      };
      let res;
      this.listLoading = true;
      try {
        if (this.examObj.examType == 1) {
          data.viewSchoolId = this.schoolId;
        } else {
          res = await topOrBackward(data);
        }
        this.listLoading = false;
      } catch {
        this.listLoading = false;
      }

      this.oldList = res.data.data || [];
      this.initList();
    },

    checkMore() {
      this.showTab = !this.showTab;
      this.initList();
    },
    toStudent(row) {
      const { href } = this.$router.resolve({
        path: "/academic/reportStat/studentReport",
        query: {
          ...this.$route.query,
          studentId: row.studentId,
          id: this.examObj.statId,
        },
      });
      window.open(href, "_blank");
    },
    async trackStudent() {
      this.listLoading = true;
      try {
        let res;
        let reqData = {
          id: this.examObj.statId,
          classNum: this.filterObj.classNum,
          subjectId: this.filterObj.subjectId,
        };
        if (this.examObj.examType == 1) {
          reqData.viewSchoolId = this.schoolId;
        } else {
          res = await trackStudent(reqData);
        }
        this.listLoading = false;
        this.oldList = res.data.data || [];
        this.initList();
      } catch {
        this.listLoading = false;
      }
    },
    chechTab() {
      this.trackStudent();
    },
    initList() {
      if (this.showTab) {
        this.tableData = this.oldList;
      } else {
        this.tableData = this.oldList.filter((item, index) => index < 5);
      }
    },
    linkTo() {
      // this.$router.push({
      //   path: "/academic/reportStat",
      //   query: { id: this.examObj.statId },
      // });
      this.$parent.linkReport();
    },
    checkItem(item) {
      if (this.listLoading) return;
      this.topOrBackward = 20;
      this.indexStudentType = item;
      this.tableData = [];
      this.initView();
    },
  },
};
</script>
<style lang="scss" scoped>
.focusOnstudents {
  .name {
    text-decoration: underline;
    cursor: pointer;
    margin-right: 10px;
    color: #2474ed;
  }
  .student-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .student-filter-left {
      display: flex;
      align-items: center;
      .el-select,
      .el-input {
        width: 120px;
        margin-right: 8px;
      }
    }
  }
  .student-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .student-type-item {
      display: flex;
      align-items: center;
      div {
        padding: 6px 10px;
        line-height: 1;
        cursor: pointer;
        margin-right: 14px;
        &:hover {
          background: #eff5ff;
          border-radius: 4px;
          color: #2474ed;
        }
      }
      .on {
        background: #eff5ff;
        border-radius: 4px;
        color: #2474ed;
      }
    }
  }
  .running-condition {
    // padding-top: 0;
    padding: 0;
    margin-bottom: 18px;
    .label-span {
      color: #83878f;
      font-size: 12px;
      margin-left: 6px;
      font-weight: 400;
    }
  }
  .seriousSubject {
    color: #fc7070;
  }
}
</style>
