<template>
  <div class="studentList">
    <el-dialog
      title="学生名单-语文"
      :visible.sync="dialogVisible"
      width="855px"
    >
      <div class="opt-box">
        <el-radio-group v-model="radio2" size="small">
          <el-radio-button label="1">前27%群体</el-radio-button>
          <el-radio-button label="2">后27%群体</el-radio-button>
        </el-radio-group>
        <el-button type="text">
          导出列表
          <img src="@/assets/dashborad/icon-export.png" alt="" />
          <img class="on" src="@/assets/dashborad/icon-export.png" alt="" />
        </el-button>
      </div>
      <div class="tips-msg">前27%群体指当前科目历次考试均分进行高</div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          prop="date"
          label="学生姓名"
          width="108"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="date" label="班级" align="center">
        </el-table-column>
        <el-table-column prop="date" label="任课教师" align="center">
        </el-table-column>
        <el-table-column prop="date" label="平均得分率" align="center">
        </el-table-column>

        <el-table-column
          prop="date"
          label="最近一次考试得分"
          width="135"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="最近一次考试排名 (年级)"
          width="135"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="最近一次考试排名 (班级)"
          width="135"
          align="center"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="current"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
      />
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "StudentList",
  data() {
    return {
      dialogVisible: false,
      radio2: "1",
      tableData: [],
      current: 1,
      size: 10,
      totalPage: 300,
    };
  },
  created() {},
  methods: {
    init() {
      this.dialogVisible = true;
    },
    sizeChangeHandle(val) {
      this.size = val;
    },
    currentChangeHandle(val) {
      this.current = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.studentList {
  .opt-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }
}
</style>
