import request from "@/core/services/axios";
export const getTopData = (params) => {
  // 获取任课教师报告顶部数据
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrenkejiaoshireporttopinfo`,
    method: "GET",
    params: params,
  });
};
export const getBranch = (params) => {
  // 新高考-任课教师报告-成绩分布
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrenkejiaoshireportscoredistribution`,
    method: "post",
    data: params,
  });
};
export const getrkjsreportclasscompetitive = (params) => {
  // 新高考-任课教师报告-班级竞争力
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportclasscompetitive`,
    method: "get",
    params: params,
  });
};
export const getSubjectCritical = (params) => {
  // 新高考-任课教师报告-重点关注学生-选科临界生
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportimportstudent/critical`,
    method: "get",
    params: params,
  });
};
export const waveStudent = (params) => {
  // 新高考-任课教师报告-重点关注学生-波动生
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportimportstudent/wave`,
    method: "get",
    params: params,
  });
};

export const topOrBackward = (params) => {
  // 新高考-任课教师报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportimportstudent/toporbackward`,
    method: "get",
    params: params,
  });
};
export const trackStudent = (params) => {
  // 新高考-任课教师报告-重点关注学生-跟踪生
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportimportstudent/track`,
    method: "get",
    params: params,
  });
};
export const getDevelopment = (params) => {
  // 教研报告--发展性分析
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportdevelopanalysis`,
    method: "get",
    params: params,
  });
};
export const getQuetions = (params) => {
  // 新高考-班主任报告-应答情况分析-题目
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportpaperanaly/question`,
    method: "get",
    params: params,
  });
};
export const getKnowledge = (params) => {
  // 教研报告--试卷分析-应答情况分析-知识点
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportpaperanaly/knowledge`,
    method: "get",
    params: params,
  });
};
export const getbzrreportpaperanaly = (params) => {
  // 新高考-班主任报告-应答情况分析-学生应答反馈
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrkjsreportpaperanaly/student`,
    method: "get",
    params: params,
  });
};
export const getrenkejiaoshireportscoredistribution = (params) => {
  // 新高考-任课教师报告-成绩分布-分数分布
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrenkejiaoshireportscoredistribution`,
    method: "post",
    data: params,
  });
};
export const getrenkejiaoshireportscoredistributionrank = (params) => {
  // 新高考-任课教师报告-成绩分布-排名分布
  return request({
    url: `/report/statnewgaokaorenkejiaoshireport/getrenkejiaoshireportscoredistributionrank`,
    method: "post",
    data: params,
  });
};
